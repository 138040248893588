import { IMultiSelectOption } from '@parashift/ngx-bootstrap-multiselect';
import { Option } from './interfaces/option.interface';
import { groupBy } from './group-by';

const noop = x => x;

export function mapOptionsToMultiSelectboxOptions(options: Option[], translate: (key: string) => string = noop): IMultiSelectOption[] {
  const grouped = groupBy(options, x => x.group);
  return Object
    .entries(grouped)
    .map(([key, values]) => [
      {
        id: '',
        name: translate(key),
        isLabel: true
      },
      ...values.map(option => ({
        id: option.value,
        name: translate(option.label),
        disabled: option.disabled
      }))
    ])
    .reduce((all, batch) => all.concat(batch), []);
}
